import React, { Component } from 'react';
import { Navbar, Nav, NavDropdown, Form, Button, FormControl, Image } from 'react-bootstrap';
import logo from '../assets/images/dreampool-logo.png';
import { FaPhoneAlt, FaWhatsapp } from 'react-icons/fa';

class Header extends Component{
    constructor(props) {
        super(props);
        this.state = {
            marginLeft: 40,
            marginRight: 40,
            marginTop:40
        }
    }

    render() {
        const { marginTop, marginLeft, marginRight } = this.state;
        return(
            <Navbar bg="white" expand="lg" style={{ marginTop: marginTop, marginLeft: marginLeft, marginRight: marginRight }}>
                <Navbar.Brand href="#home"><Image src={logo} width="150"/></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                    </Nav>
                    <Nav>
                        <Nav.Link href="#deets"><p className="navigation-link">Services</p></Nav.Link>
                        <Nav.Link href="#deets"><p className="navigation-link">About</p></Nav.Link>
                        <Nav.Link href="#deets"><p className="navigation-link">Client</p></Nav.Link>
                        <Nav.Link href="#deets"><p className="navigation-link">Products</p></Nav.Link>
                        <Nav.Link href="#deets"><p className="navigation-link">Contact</p></Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

export default Header;