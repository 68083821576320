import React, { Component } from 'react';
import { Image } from 'react-bootstrap';

class ProcessCard extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { name, image } = this.props;
        return(
            <div className="text-center process">
                <Image src={image} className="process-image" />
                <p className="process-heading">{name}</p>
            </div>
        );
    }
}

export default ProcessCard;