import React, { Component } from 'react';
import { Navbar, Nav, NavDropdown, Form, Button, FormControl, Image, Container, Row, Col } from 'react-bootstrap';
import logo from '../assets/images/dreampool-logo.png';
import svg1 from '../assets/svg/1.svg';
import svg3 from '../assets/svg/3.svg';
import svg4 from '../assets/svg/4.svg';
import svg5 from '../assets/svg/5.svg';
import Tilt from 'react-tilt';
import { FaPhoneAlt, FaWhatsapp } from 'react-icons/fa';
import Header from '../components/header';
import Footer from '../components/footer';
import ProcessCard from '../components/processCard';
import Presence from '../components/presence';

class Home extends Component{
    constructor(props) {
        super(props);
        this.state = {
            currentImage: 0,
            images: [svg1, svg3, svg4, svg5],
            processCards : [
                {
                    "name" : "Ideation",
                    "image" : svg1
                },
                {
                    "name" : "Design",
                    "image" : svg3
                },
                {
                    "name" : "Code",
                    "image" : svg4
                },
                {
                    "name" : "Test",
                    "image" : svg5
                },
                {
                    "name" : "Deploy",
                    "image" : svg1
                },
                {
                    "name" : "Support",
                    "image" : svg3
                },
            ]
        };
    }

    switchImage = () => {
        if (this.state.currentImage < this.state.images.length - 1) {
          this.setState({
            currentImage: this.state.currentImage + 1
          });
        } else {
          this.setState({
            currentImage: 0
          });
        }
        return this.currentImage;
    }

    componentDidMount() {
        setInterval(this.switchImage, 5000);
    }

    render() {
        const { images, currentImage, processCards } = this.state;
        return(
            <div>
                <Header />
                <Container>
                    <Row className="block-container">
                        <Col xs={12} lg={6}>
                            <Tilt className="Tilt" options={{ max : 10 }} >
                                <Image src={images[currentImage]} className="home-image" />
                            </Tilt>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Tilt className="Tilt" options={{ max : 10 }} >
                                <p className="main-text">Get a dedicated Agile Team for your project</p>
                                <p className="main-subtext text-muted">At Dreampool, we specialize in delivering an end-to-end solution for your project. We have teams to handle every aspect of your digital presence.</p>
                            </Tilt>
                            <div className="main-buttons">
                                <a className="main-button" href="tel:+919999247254"><FaPhoneAlt /> Call Now</a>
                                <a className="main-button" href="https://wa.me/917982438653"><FaWhatsapp /> WhatsApp</a>
                            </div>
                        </Col>
                    </Row>
                    <Row className="block-container">
                        <Col lg={12}>
                            <p className="main-text text-center">We have 50+ satisfied clients worldwide.</p>
                            <p className="main-subtext2 text-muted text-center">Let us take a visual look through World Map.</p>
                            <Presence />
                        </Col>
                    </Row>
                    <Row className="block-container">
                        <Col lg={12}>
                            <p className="main-text text-center">Our Work Process</p>
                            <p className="main-subtext2 text-muted text-center">We follow a Systematic Agile Method for all our projects, irrespective of it's size.</p>
                            <Row className="processes">
                                {processCards.map(card => 
                                    <Col lg={4} xs={12}>
                                        <ProcessCard name={card.name} image={card.image} />
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </div>
        );
    }
}

export default Home;