import React, { Component } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import logo from '../assets/images/dreampool-logo.png';
import { FaPhoneAlt, FaWhatsapp, FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa';

class Footer extends Component{
    constructor(props) {
        super(props);
    }
    render() {
        return(
            <div>
                <div className="footer">
                    <Image src={logo} width={200} style={{marginBottom:20}} />
                    <Row>
                        <Col lg={4}>
                            <p>Dreampool Developers Pvt. Ltd.</p>
                            <p>70/B-37, Satguru Ram Singh Ji Marg, Rama Road,</p>
                            <p>New Delhi - 110015</p>
                        </Col>
                        <Col lg={4}>
                            <p>Our Team</p>
                            <p>Privacy Policy</p>
                            <p>Our Story</p>
                        </Col>
                    </Row>
                </div>
                <div className="social">
                    <a target="_blank" href="https://www.facebook.com/dreampooldev" className="social-icon"><FaFacebook className="social-icon" size={30} /></a>
                    <a target="_blank" href="https://twitter.com/dreampoolind" className="social-icon"><FaTwitter className="social-icon" size={30} /></a>
                    <a target="_blank" href="https://www.linkedin.com/company/dreampooldev/" className="social-icon"><FaLinkedin className="social-icon" size={30} /></a>
                    <p className="main-subtext text-muted">Follow us on Social Media to get the latest updates and insights.</p>
                </div>
            </div>
        );
    }
}

export default Footer;